/* eslint-disable react/no-unescaped-entities */

import React, { useEffect } from "react";
import Layout from "../components/layout";
import Seo from "../components/seo";
import * as CONST from "../CONSTANTS";
// import ReactGA from 'react-ga'

const TermsAndCond = () => {
  useEffect(() => {
    // ReactGA.initialize('UA-115903172-9');
    // ReactGA.pageview(window.location.pathname + window.location.search);
    // ReactGA.event({
    //   category:'event',
    //   action: 'conversion'
    // })
  }, []);

  return (
    <Layout layoutType={CONST.LAYOUT_ORDINARY_PAGE}>
      <Seo title="Terms and Conditions" />
      <div className="terms-and-conditions-section">
        <div className="container">
          <div className="section-header">
            <h2>Terms of Services</h2>
            <p>
              Last Update 12 Oct, 2020 <br></br>
              We've tried our best to make this as easy to read in layman's
              terms while also keeping our legal<br></br>
              team happy. Please read our terms of service below.
            </p>
          </div>

          <h4>1. Terms</h4>
          <p>
            By accessing the website at{" "}
            <a href="https://www.healthy-quotes.com/">www.healthy-quotes.com</a>
            , you are agreeing to be bound by these terms of service, all
            applicable laws and regulations, and agree that you are responsible
            for compliance with any applicable local laws. If you do not agree
            with any of these terms, you are prohibited from using or accessing
            this site. The materials contained in this website are protected by
            applicable copyright and trademark law.
          </p>

          <h4>2. Use License</h4>
          <ul className="page-ul">
            <li>
              Permission is granted to temporarily download one copy of the
              materials (information or software) on Lendmarx 's website for
              personal, non-commercial transitory viewing only. This is the
              grant of a license, not a transfer of title, and under this
              license you may not:
            </li>

            <ul className="page-ul">
              <li>modify or copy the materials;</li>
              <li>
                use the materials for any commercial purpose, or for any public
                display (commercial or non-commercial);
              </li>
              <li>
                attempt to decompile or reverse engineer any software contained
                on Lendmarx 's website;
              </li>
              <li>
                remove any copyright or other proprietary notations from the
                materials; or
              </li>
              <li>
                transfer the materials to another person or "mirror" the
                materials on any other server.
              </li>
            </ul>

            <li>
              This license shall automatically terminate if you violate any of
              these restrictions and may be terminated by Lendmarx at any time.
              Upon terminating your viewing of these materials or upon the
              termination of this license, you must destroy any downloaded
              materials in your possession whether in electronic or printed
              format.
            </li>
          </ul>

          <h4>3. Disclaimer</h4>
          <ul className="page-ul">
            <li>
              The materials on Lendmarx 's website are provided on an 'as is'
              basis. Lendmarx makes no warranties, expressed or implied, and
              hereby disclaims and negates all other warranties including,
              without limitation, implied warranties or conditions of
              merchantability, fitness for a particular purpose, or
              non-infringement of intellectual property or other violation of
              rights.
            </li>
            <li>
              Further, Lendmarx does not warrant or make any representations
              concerning the accuracy, likely results, or reliability of the use
              of the materials on its website or otherwise relating to such
              materials or on any sites linked to this site
            </li>
          </ul>

          <h4>4. Limitations</h4>

          <p>
            In no event shall Lendmarx or its suppliers be liable for any
            damages (including, without limitation, damages for loss of data or
            profit, or due to business interruption) arising out of the use or
            inability to use the materials on Lendmarx 's website, even if
            Lendmarx or a Lendmarx authorized representative has been notified
            orally or in writing of the possibility of such damage. Because some
            jurisdictions do not allow limitations on implied warranties, or
            limitations of liability for consequential or incidental damages,
            these limitations may not apply to you
          </p>

          <h4>5. Accuracy of materials</h4>

          <p>
            The materials appearing on Lendmarx 's website could include
            technical, typographical, or photographic errors. Lendmarx does not
            warrant that any of the materials on its website are accurate,
            complete or current. Lendmarx may make changes to the materials
            contained on its website at any time without notice. However
            Lendmarx does not make any commitment to update the materials
          </p>

          <h4>6. Links</h4>

          <p>
            Lendmarx has not reviewed all of the sites linked to its website and
            is not responsible for the contents of any such linked site. The
            inclusion of any link does not imply endorsement by Lendmarx of the
            site. Use of any such linked website is at the user's own risk.
          </p>

          <h4>7. Modifications</h4>

          <p>
            Lendmarx may revise these terms of service for its website at any
            time without notice. By using this website you are agreeing to be
            bound by the then current version of these terms of service.
          </p>

          <h4>8. Governing Law</h4>

          <p>
            These terms and conditions are governed by and construed in
            accordance with the laws of NV and you irrevocably submit to the
            exclusive jurisdiction of the courts in that State or location.
          </p>

          <h4>9. Arbitration Agreement</h4>

          <p>
            Any dispute or claim relating in any way to your use of this
            website, including any related calls texts or other communications,
            will be resolved by binding arbitration, rather than in court,
            except that you may assert claims in small claims court if your
            claims qualify. This includes claims against our clients, vendors,
            and Marketing Partners, which are third party beneficiaries of this
            arbitration agreement. The Federal Arbitration Act and federal
            arbitration law apply to this agreement.
          </p>

          <p>
            There is no judge or jury in arbitration, and court review of an
            arbitration award is limited. However, an arbitrator can award on an
            individual basis the same damages and relief as a court (including
            injunctive and declaratory relief or statutory damages), and must
            follow the terms of these terms as a court would.
          </p>

          <p>
            The arbitration may be conducted by the American Arbitration
            Association (AAA) under its rules, including the AAA's Supplementary
            Procedures for Consumer-Related Disputes. The AAA's rules are
            available at www.adr.org or by calling 1-800-778-7879. You may
            choose to have the arbitration conducted by telephone, based on
            written submissions, or in person in the county where you live or at
            another mutually agreed location.
          </p>

          <p>
            We each agree that any dispute resolution proceedings will be
            conducted only on an individual basis and not in a class,
            consolidated or representative action. If for any reason a claim
            proceeds in court rather than in arbitration we each waive any right
            to a jury trial. We also both agree that you or we may bring suit in
            court to enjoin infringement or other misuse of intellectual
            property rights.
          </p>
        </div>
      </div>
    </Layout>
  );
};

export default TermsAndCond;
